@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap");
.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: red; */
  color: #1e3d8d;
}

.App-link {
  color: #afb7b9;
}

.install-button {
  height: 2rem;
  width: 10rem;
  font-size: small;
}
body {
  font-family: "Inter", sans-serif !important;
}
.outer-wrap {
  width: 100%;
  height: auto;
  background: #dee2e6;
}

.custom-card {
  min-width: 320px;
  width: 100%;
  min-height: 100%;
  margin: 20px auto;
  box-shadow: 5px 5px 12px 0px #00000038;
  border-radius: 15px;
  border: none !important;
}

.name-fields h3 {
  font-size: 13px;
  font-weight: 400;
}
.content-text p {
  font-size: 15px;
  font-weight: 400;
}

span.c_icon svg {
  width: 15px;
  height: 15px !important;
  fill: #133d8d;
}
.share_contact input:focus-visible {
  outline: none !important;
  border-bottom: 1px solid #133d8d;
}

.cta-button button {
  width: 70%;
  margin: 10px 0px;
  padding: 10px;
  background: #133d8d !important;
  border-radius: 50px;
  border: 1px solid #133d8d;
  text-transform: uppercase;
  transition: 0.3s ease;
}

.custom_card-header {
  display: flex;
  justify-content: space-between;
}

.topic h3 {
  font-size: 20px;
  font-weight: 400;
}

span.close_icon:hover > svg {
  transform: rotate(90deg);
  cursor: pointer;
  transition: 0.3s ease;
}

span.close_icon svg {
  transform: rotate(0deg);
  transition: 0.3s ease;
}

.qr-block {
  margin: 50px auto;
  text-align: center;
}

.share-button {
  text-align: center;
  margin: 0px auto;
  color: white;
}

.share-button a {
  color: white;
  fill: white;
  transition: 0.3s ease;
  background: #133d8d;
  padding: 20px 40px;
  text-decoration: none;
  text-transform: uppercase;
}

.share-button a:hover {
  background: white;
  color: #133d8d;
  fill: #133d8d;
  border: 1px solid #133d8d;
  transition: 0.3s ease;
}

.mar_t_50 {
  margin-top: 35%;
}

.four_cta ul {
  list-style: none;
  display: flex;
  border-radius: 20px;
  margin-top: 25%;
  padding: 15px;
}

.four_cta ul li {
  display: inline-grid;
  text-align: center;
  padding: 10px;
  border: 1px solid #ffffff;
  width: 100px;
  background: #133d8d;
  color: white;
  transition: 0.3s ease;
}

.four_cta li:hover {
  color: #133d8d;
  background: white;
  border: 1px solid #133d8d;
  transition: 0.3s ease;
  cursor: pointer;
}

.cta-button button.btn.btn-primary:hover {
  background: white !important;
  color: #133d8d;
  border: 1px solid #133d8d;
  transition: 0.3s ease;
}

.share-button svg {
  margin-bottom: 10px;
  margin-right: 5px;
}

span.c_icon.share_icon:hover > svg {
  fill: #13469d;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.5s ease;
}

span.c_icon.share_icon svg {
  transition: 0.5s ease;
  cursor: pointer;
}

.color-design-block {
  min-height: 200px;
  border-radius: 40px 40px 0px 0px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-bottom: 35px;
}

.card.bg-white.custom-card.rounded-edge {
  border-radius: 40px !important;
}

.cta-button {
  text-align: center;
}

.round-img {
  padding: 20px;
  border-radius: 50%;
  border: 1px solid #e4e4ed;
  width: 180px;
  height: 180px;
  margin: 0px auto;
  /* background-image: url(/src/assets/img/pp.jpeg); */
  background-position: top;
  background-size: cover;
  position: relative;
}

.color-design-block {
  background: linear-gradient(60deg, #007bff, #133d8d, #007bff);
  background-size: 600% 460%;
  -webkit-animation: bk-change 18s ease infinite;
  -moz-animation: bk-change 18s ease infinite;
  animation: bk-change 10s ease infinite;
}

@-webkit-keyframes bk-change {
  0% {
    background-position: 7% 0%;
  }

  50% {
    background-position: 94% 100%;
  }

  100% {
    background-position: 7% 0%;
  }
}

@-moz-keyframes bk-change {
  0% {
    background-position: 7% 0%;
  }

  50% {
    background-position: 94% 100%;
  }

  100% {
    background-position: 7% 0%;
  }
}

@keyframes bk-change {
  0% {
    background-position: 7% 0%;
  }

  50% {
    background-position: 94% 100%;
  }

  100% {
    background-position: 7% 0%;
  }
}

#rotation {
  -webkit-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.sonar-wave {
  margin: 0px auto;
  width: 100px;
  height: 100px;
  border-radius: 9999px;
  background-image: linear-gradient(to right, #d8d8d8 0%, #d8d8d8 100%);
  opacity: 0;
  z-index: 0;
  pointer-events: none;
  position: absolute;
}
.sonar-wave {
  animation: sonarWave 2s linear infinite;
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }

  to {
    transform: scale(1.3);
    opacity: 0;
  }
}

.waves {
  position: absolute;
  width: 100%;
  height: 100px;
  margin-bottom: 0px;
  min-height: 50px;
  max-height: 65px;
  top: 160px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

.name-fields {
  border-bottom: 2px dashed #3c3c3c;
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.name-fields h1 {
  font-size: 20px;
  font-weight: 600;
  color: #133d8d;
}

.s_icon {
  width: 30px;
  height: 30px;
}
.social-media {
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.social-media a:hover .s_icon svg {
  bottom: 5px;
  transition: 0.5s ease;
  position: relative;
  cursor: pointer;
}

.social-media a .s_icon svg {
  transition: 0.5s ease;
}

.phone .svg-icon path {
  fill: #133d8d;
}

.phone .svg-icon {
  width: 25px;
  height: 25px !important;
  margin: 0px 5px;
}

.contact-details {
  margin-top: 20px;
  text-align: center;
}

.phone {
  margin: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-f-20 {
  padding: 20px;
  position: relative;
  z-index: 10;
  margin-top: -40px;
}

.phone a {
  color: #133d8d;
  font-size: 12px;
  text-decoration: none;
  font-weight: 500;
}

.phone:hover > span a {
  color: black;
}

.phone:hover > .svg-icon path {
  fill: black;
}

.custom_topic h3 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.custom_card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
span.svg-icon-form svg {
  width: 25px;
  height: 25px;
}
span.svg-icon-form svg path {
  fill: #133d8d;
}
form.custom_form .share_contact {
  display: flex;
  justify-content: center;
  align-items: center;
}
.share_contact input {
  font-size: 13px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #000000;
  margin-left: 15px;
  padding: 10px 5px;
  background: white;
  border-radius: 0px !important;
}
form.custom_form .share_contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}
body {
  background: #dee2e6 !important;
}
.no-padding {
  padding: 0px !important;
}
.logo-place img {
  width: 130px;
  padding: 5px;
}
.logo-place {
  text-align: center;
  margin-bottom: 10px;
  margin-top: -20px;
}
.round-img.custom-pp {
  width: 100px;
  height: 100px;
}
.name-section .name-fields {
  padding-left: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  text-align: left;
  border: none !important;
}
.b-b-2 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.profile-photo {
  margin: 20px 0px;
}
.name-section {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rounded-edge-custom {
  border-radius: 40px !important;
}
.logo-place.custom-logo h2 {
  font-size: 15px;
}

.logo-place.custom-logo {
  margin: 10px 20px;
  padding: 0px !important;
}
.text-white h1,
.text-white h3 {
  color: white !important;
}
.p-20 {
  padding: 5px;
}

.h-100 {
  min-height: 100px !important;
}

.scan h3 {
  font-size: 20px;
  font-weight: 200;
  color: white;
  margin-bottom: 0px;
}
.m-t-100 {
  margin-top: 100px;
}

.blue-card-400 {
  background: linear-gradient(60deg, #007bff, #133d8d, #007bff);
  background-size: 600% 460%;
  animation: bk-change 10s ease infinite;
  min-height: 100px !important;
  border-radius: 15px !important;
  border: none !important;
  margin-top: 30px;
}

.scan {
  padding: 20px 0px;
  margin: 20px auto;
}

.card-white-custom {
  min-height: 100%;
  background: white;
  border-radius: 15px;
  border: none;
  box-shadow: 0px 1px 12px 3px #00000045;
}
.name-part {
  text-align: center;
}
.name-part h1 {
  font-size: 18px;
}
.name-part h3 {
  font-size: 14px;
  color: #144ba7;
}
.round-img.small-pp {
  width: 80px;
  height: 80px;
}
.c-qr-block img {
  width: 65%;
}
.c-qr-block {
  margin: 15px auto;
  text-align: center;
  padding: 10px;
}
.profile-section {
  margin: 10px;
}
span.svg-icon-btn svg {
  width: 20px;
  height: 20px;
  margin-left: 14px;
  fill: white;
  margin-bottom: 8px;
}

.float {
  position: fixed;
  width: 50px;
  height: 50px;
  top: 6px;
  right: 3px;
  background-color: #133d8d;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 0px 0px 0px 4px #dce0e4;
  z-index: 100;
}

.label-container {
  position: fixed;
  top: 10px;
  right: 55px;
  display: table;
  visibility: hidden;
  z-index: 100;
}

.label-text {
  color: #fff;
  background: rgba(51, 51, 51, 0.5);
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  border-radius: 3px;
  z-index: 100;
}

.label-arrow {
  display: table-cell;
  vertical-align: middle;
  color: #333;
  opacity: 0.5;
  z-index: 100;
}

.my-float {
  font-size: 24px;
  margin-top: 18px;
}

a.float + div.label-container {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

a.float:hover + div.label-container {
  visibility: visible;
  opacity: 1;
}
span.icon-home svg {
  fill: white;
  padding: 16px;
}
span.icon-btn svg {
  width: 20px;
  height: 20px;
  fill: white;
  margin-bottom: 5px;
}
span.icon-home svg {
  width: 100%;
  height: auto;
}

.broken-qr img {
  width: 100%;
  height: auto;
  padding: 50px;
}
img.home-img {
  width: 100%;
  height: auto;
}
.introPara.msg_home {
  color: #133d8d;
  display: grid;
  padding: 20px;
}
.introPara.msg_home strong {
  color: #141414;
  font-size: 20px;
}
.introPara.msg_home span {
  background: #f5f5f5;
  padding: 10px;
  color: #133d8d;
  margin-top: 25px;
  border: 1px solid #b7b7c0;
  border-radius: 5px;
  font-size: 12px;
}
.introPara.desc-vcard {
  font-size: 13px;
  margin: 10px 20px;
}
.row.mobile-row {
  flex-wrap: nowrap;
  width: 100%;
  margin: 0px auto;
}
.cta-button button:hover > span.svg-icon-btn svg {
  fill: #1e3d8d;
}

.red-card-400 {
  background-color: red !important;
  background-size: 600% 460%;
  animation: bk-change 10s ease infinite;
  min-height: 100px !important;
  border-radius: 15px !important;
  border: none !important;
  margin-top: 25px;
}

.social-media a {
  margin: 0px 15px;
}
.custom_width {
  min-width: 320px;
  max-width: 400px;
  margin: 0px auto;
}
.new-header .close_icon {
  background: #9e9e9e;
  padding: 10px;
  border-radius: 50px;

  line-height: 0px;
}
.custom_topic {
  width: 85%;
}
.new-header .close_icon svg {
  fill: white;
  width: 10px;
  height: 10px !important;
}
.new-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.share_contact label {
  margin-bottom: 0px;
}
.error {
  position: absolute;
  float: right;
  right: 25px;
  font-size: 9px;
  color: red;
}
.share_contact input:focus {
  outline: none !important;
}

.language-switch span {
  color: #124aa5;
  margin-top: -7px;
  cursor: pointer;
}
.language-switch {
  position: absolute;
  float: right;
  right: 25px;
  top: 15px;
  color: white;
  background: white;
  padding: 5px;
  text-align: center;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 20px;
}

.ar .language-switch {
  float: left !important;
  left: 25px;
}
.ar .d-flex.color-design-block {
  flex-direction: row-reverse;
}
.ar .name-section .name-fields {
  padding-right: 20px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  text-align: right;
  border: none !important;
}
.ar .contact-details .phone {
  flex-direction: row-reverse;
}
.ar .new-header {
  flex-direction: row-reverse;
}
.ar .share_contact {
  flex-direction: row-reverse;
}
.ar .share_contact input {
  margin-left: 0px;
  margin-right: 15px;
  text-align: right;
}
.ar .error {
  position: absolute;
  float: left;
  left: 25px;
  font-size: 9px;
  color: red;
}
.branding-color path {
  fill: #133d8d;
}
svg.branding-color-add circle {
  fill: #133d8d;
}
.mobile-color path {
  fill: #007bff;
}
.email-color path {
  fill: #f3b600;
}
.ar .language-switch span {
  font-size: 10px;
  margin-top: -2px;
  cursor: pointer;
}
